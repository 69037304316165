import "./LandingPage.scss";

import mainImage from "src/assets/speak-like-a-native.png";
console.log(mainImage);

function LandingPage() {
  return (
    <div className="LandingPage">
      <h1>This is the temp landing page for now.</h1>
      <img src={mainImage} alt="" />
    </div>
  );
}

export default LandingPage;
