import "./App.scss";
import { Route, Routes } from "react-router-dom";

import LandingPage from "src/components/LandingPage/LandingPage.jsx";

function App() {
  return (
    <div className="App">
      {/* <NavBar /> */}
      <Routes>
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </div>
  );
}

export default App;
